import React, {useEffect} from "react";
import {gql, useLazyQuery} from "@apollo/client";

function Title(prop) {

    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
    query GetCategory ($category: ID!, $sub_category: ID!) {
        category(id: $category) {
            name
        }
        subCategory(id: $sub_category) {
            name
        }
    }
    `,{
        variables: {
            category: prop.category,
            sub_category: prop.sub
        }
    })

    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div>
            {data != undefined && (
                <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                    <span className="text-3xl text-green-dark font-extrabold tracking-tight sm:text-4xl">
                        {data.category.name}&nbsp;
                    </span>
                    <span className="text-3xl text-green-dark font-extrabold tracking-tight sm:text-4xl">
                        &#47;&#47;
                    </span>
                    <span className="text-3xl text-green-dark tracking-tight sm:text-4xl">
                        &nbsp;{data.subCategory.name}
                    </span>
                </div>
            )}
        </div>
    )
}

export default Title;
