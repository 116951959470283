import React, {useEffect} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import Link from "gatsby-link";

function Pagination(prop) {
    const paginationElements = [];
    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(gql`
        query recordsCount ($category: ID, $sub_category: ID, $artistFirst: String) {
            recordsConnection (
                where: { 
                    artistFirst_contains: $artistFirst,
                    category: {id: $category}, 
                    sub_category: {id: $sub_category}  
                }
            )
            {
                aggregate {
                    count
                }
            }
        }`,
        {
            variables: {
                category: prop.category,
                sub_category: prop.sub,
                artistFirst: prop.where == "" ? "" : prop.where
            }
        }
    )

    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    if (data != undefined) {
        for (let i=0; i < Math.ceil(data.recordsConnection.aggregate.count/prop.limit); i++) {
            paginationElements.push(i)
        }
    }

    return (

        <div className="mx-auto py-6 px-4 max-w-7xl sm:px-6 lg:px-8">
            { data != undefined && (
                <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                    <div className="-mt-px w-0 flex-1 flex">
                        {prop.start > 0 && (
                            <Link
                                //onClick={() => sendData(prop.start-prop.limit)}
                                to={"/page?category=" + prop.category + "&sub=" + prop.sub + "&start=" + (prop.start-prop.limit)}
                                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                                Zurück
                            </Link>
                        )}
                    </div>
                    <div className="hidden md:-mt-px md:flex">
                        {paginationElements.map((value, index) => (
                            <Link
                                key={index}
                                to={"/page?category=" + prop.category + "&sub=" + prop.sub + "&start=" + (Number(value) * Number(prop.limit))}
                                //onClick={() => sendData(Number(value) * Number(prop.limit))}
                                className={(prop.start/prop.limit) == value ? "border-green-dark text-green-dark border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"}>
                                {Number(value)+1}
                            </Link>
                            )
                        )}
                    </div>
                    <div className="-mt-px w-0 flex-1 flex justify-end">
                        {Number(prop.start) + Number(prop.limit) < Number(data.recordsConnection.aggregate.count) && (
                            <Link
                                to={"/page?category=" + prop.category + "&sub=" + prop.sub + "&start=" + (Number(prop.start) + Number(prop.limit))}
                                //onClick={() => sendData(prop.start+prop.limit)}
                                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                Weiter
                                <svg className="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                          clipRule="evenodd"/>
                                </svg>
                            </Link>
                        )}
                    </div>
                </nav>
            )}
        </div>
    )
}

export default Pagination;
