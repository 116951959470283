import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import Title from "./title";
import Link from "gatsby-link";
import {DelayInput} from "react-delay-input/lib/Component";

const thisQuery = gql`
    query GetRecords($category: ID, $sub_category: ID, $limit: Int, $start: Int, $artistFirst: String) {
        records (where: {
            artistFirst_contains: $artistFirst,
            category: {
                id: $category
            },
            sub_category: {
                id: $sub_category
            },
        },
        sort: "artistFirst:asc,inner_sort:asc" 
        limit: $limit, 
        start: $start
        ) {
            id
            title
            artistFirst
            artistSecond
            songA
            songB
            label
            comment
            covers {
                url
            }
        }
    }`

function Covers(prop) {

    const [search, setSearch] = useState(0)
    const [loadExpenseStatus, { loading, error, data }] = useLazyQuery(thisQuery,
        {
            variables: {
                category: prop.category,
                sub_category: prop.sub,
                artistFirst: search == "" ? "" : search,
                limit: Number(prop.limit),
                start: Number(prop.start),
            }
        }
    )
    useEffect(() => {
        loadExpenseStatus( {
            variables: {
                date: data
            }
        })
    }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    const handleSearch = e => {
        setSearch(e.target.value)
        prop.parentCallback({ start: 0, limit: prop.limit, where: e.target.value});
    }

    return (
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12">
                <div className="inline-flex">
                    {prop != undefined && (
                        <Title category={prop.category} sub={prop.sub}/>
                    )}
                    <div className="ml-10 w-full flex">
                        <label htmlFor="search_field" className="sr-only">Search</label>
                        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                <svg className="h-5 w-5" x-description="Heroicon name: solid/search"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                          clipRule="evenodd"></path>
                                </svg>
                            </div>
                            <DelayInput
                                minLength={3}
                                delayTimeout={300}
                                onChange={ handleSearch}
                                className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900
                                placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0
                                focus:border-transparent sm:text-sm"
                                placeholder="Suche"
                                value={search}
                                type="search"
                                name="search"/>
                        </div>
                    </div>
                </div>

                <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
                    {data != undefined && data.records.map(record => (
                        <li key={record.id}>
                            <div className="space-y-4">
                                <Link to={`/entry?id=${record.id}&start=${prop.start}`} >
                                    <div className="aspect-w-1 aspect-h-1">
                                        { record.covers[0] != undefined && (
                                            // process.env.GATSBY_STAPI_GRAPHQL +
                                            <img id={record.id}
                                                 className="object-scale-down cursor-pointer shadow-lg rounded-lg"
                                                 src={record.covers[0].url} alt=""/>
                                        )}
                                    </div>
                                </Link>

                                <div className="space-y-2">
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                        <h3 className="text-green-dark font-bold">
                                            {
                                                ( record.artistFirst != null && record.artistFirst != "" ) ? record.artistFirst : ""
                                            }
                                            {
                                                ( record.artistSecond != null && record.artistSecond != "" ) ? " | " + record.artistSecond : ""
                                            }
                                        </h3>
                                        {/*
                                        <h2 className="text-green-light">
                                            {
                                                record.title != "" ? record.title : ""
                                            }
                                        </h2>
                                        */}
                                    </div>
                                    {/*
                                    <div className="font-normal">
                                        <p className="text-sliver">
                                            {
                                                record.songA != "" ? "A: " + record.songA : ""
                                            }
                                        </p>
                                        <p className="text-sliver">
                                            {
                                                record.songB != "" ? "B: " + record.songB : ""
                                            }
                                        </p>
                                    </div>
                                    <div className="text-sm font-normal">
                                        <p className="text-sliver">
                                            {
                                                record.label != "" ? record.label : ""
                                            }
                                        </p>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </li>
                    ))}

                </ul>
            </div>
        </div>
    )
}

export default Covers;
