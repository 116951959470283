import React, {useState} from "react";

import Layout from "../components/layout";

import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import createUploadLink from "apollo-upload-client/public/createUploadLink";
import Covers from "../components/covers";
import Pagination from "../components/pagination";
import {useLocation} from "@reach/router";
import queryString from "query-string";

const readGetParams = (query) => {
    const fallback = {category: 1, sub: 1, start: 0};
    if (query) {
        return queryString.parse(query);
    }
    return fallback;
};

const Page = () => {
    const [state, setState] = useState({ limit: 20, where: "" })

    const location = useLocation();
    const values = (location.search && readGetParams(location.search)) || {category: 1, sub: 1, start: 0};
    const cache = new InMemoryCache();
    const client = new ApolloClient({
        cache,
        link: createUploadLink({
            uri: process.env.GATSBY_STAPI
        })
    });

    const paginationCallbackFunction = (childStartData) => {
        setState(childStartData)
    }

    return (
        <ApolloProvider client={client}>
            <Layout>
                <div className="bg-white">
                    <Covers
                        parentCallback={paginationCallbackFunction}
                        category={values.category}
                        sub={values.sub}
                        start={values.start}
                        limit={state.limit}
                    />
                    <Pagination
                        parentCallback={paginationCallbackFunction}
                        category={values.category}
                        sub={values.sub}
                        start={values.start}
                        limit={state.limit}
                        where={state.where}
                    />
                </div>
            </Layout>
        </ApolloProvider>
    );
}

export default Page;
